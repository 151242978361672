import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";

const ROOT_API = process.env.REACT_APP_API_URL

function Job(props) {
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("Loading ...");
    const [jobNo, setJobNo] = useState("");

    const [isLoadingJob, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    var { id } = useParams()

    function search () {
        props.history.push(`/reference/${jobNo}`);
    }

    useEffect(() => {
        const fetchReference = async () => {
            try {
                const data = await fetch (`${ROOT_API}/reference/${id}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                        "Accept": "application/json; charset=UTF-8",
                        "Connection": "close",
                        "session-id": JSON.parse(localStorage.getItem('currentUser'))["session-id"]
                    }
                });
                const dataJSON = await data.json();          
                if (dataJSON["error-code"] === 404) {
                    setErrorMsg(dataJSON["error-message"]);
                    setIsError(true);
                } else {
                    setIsLoading(false);
                    setData(dataJSON.data.jobs);
                };
            } catch (error) {
                setIsLoading(false);
                setErrorMsg(error.message)
                setIsError(true);
            }      
        }
        fetchReference()
    },[id, isLoadingJob])
    
    return (            
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4 py-5">
            <div className="row col-12 center">
                <div className="col-md-4 col-md-offset-4 center"> 
                    <h2>Find A Job (by Reference Number)</h2>
                    <form className="form-inline my-2 my-lg-0">
                        <input className="form-control mr-sm-2" placeholder="Find Job" aria-label="Search" value={jobNo} onChange={e => {
                                    setJobNo(e.target.value);}}/>
                        <button className="btn btn-outline-success my-2 my-sm-0" onClick={search}>Search</button>
                    </form>
                </div>
            </div>
            
            <div className="row col-12 center py-5">
                <div className="col-md-4 col-md-offset-4"> 
                
                    {isLoadingJob ? (<div>{errorMsg}</div>) : (
                    <div className="table">
                        <table>
                            <thead>
                            <tr>
                                <th>Repair Reference</th>
                                <th>Policy No.</th>
                                <th>Date Added</th>
                                <th>Product Type</th>
                                <th>Job Status</th>
                                <th>Expected Dispatch Date</th>
                                <th>Additonal Info</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map(item => {
                                return (
                                    <tr>
                                        <td>{item["repair-reference"]}</td>
                                        <td>{item["policy-no"]}</td>
                                        <td>{item["file-date"]}</td>
                                        <td>{item["product-type"]}</td>
                                        <td>{item["job-status"]}</td>
                                        <td>{item["status-date"]}</td>
                                        <td>{item["spare-text3"]}</td>                                    
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                    )}
                </div>
            </div>
        </main>
    );
}

export default Job
