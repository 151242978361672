import styled from "styled-components";
import { space } from 'styled-system';

const Card = styled.div`
    box-sizing: border-box;
    max-width: 410px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;`;

    
const Form = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    `;

const Input = styled.input`
    margin-bottom: 1rem;
    
    `;

const Select = styled.select`
    border: 1px solid #999;
    margin-bottom: 1rem;
    font-size: 0.8rem;
    `;

const Button = styled.button`
    background: #7cc763;
    color: white;
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: 0.8rem;
    `;

const Logo = styled.img`
    width: 30%;
    margin-bottom: 1rem;
    `;

const Error = styled.div`
    background-color: red;
    `;

const Spacer = styled.div(space)

export { Form, Input, Select, Button, Logo, Card, Error , Spacer};