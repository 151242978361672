import React, {useState} from "react";

function Status(props) {
    const [jobNo, setJobNo] = useState("");
    const [repairReference, setRepairReference] = useState("");

    // 
    function getJob(e) {
        props.history.push(`/policy/${jobNo}`);
    }

    function getRepair() {
        props.history.push(`/reference/${repairReference}`)
    }

    return (            
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 mb-2">
            <div className="col-md-4 col-md-offset-4"> 

                <h3>Find A Job (by Policy Number)</h3>
                <form className="form-inline my-2 my-lg-0">
                    <input className="form-control mr-sm-2" placeholder="Find Job" aria-label="Search" value={jobNo} onChange={e => {
                                setJobNo(e.target.value);}}/>
                    <button className="btn btn-outline-success my-2 my-sm-0" type="submit" onClick={getJob}>Search</button>
                </form>
            </div>
            <div className="col-md-4 col-md-offset-4"> 

                <h3>Find A Job (by Repair Reference)</h3>
                <form className="form-inline my-2 my-lg-0">
                    <input className="form-control mr-sm-2" placeholder="Find Job" aria-label="Search" value={repairReference} onChange={e => {
                                setRepairReference(e.target.value);}}/>
                    <button className="btn btn-outline-success my-2 my-sm-0" type="submit" onClick={getRepair}>Search</button>
                </form>
            </div>
        </main>
    );
}

export default Status