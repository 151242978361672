import React from "react";
import {Spacer} from '../styled-components/RequestForm';

import "./dashboard.css"
import JobForm from "../_components/Form";

function RequestForm(props) {
    return(
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 mb-2">

            {/* Help Box Goes Here */}
            <div>
                <h2>Help</h2>
                <details>
                    <summary>Can't Submit</summary>
                    <ul> 
                        <li>Check all red outlined boxes are filled out.</li>
                        <li>Post Codes need to be valid (less than 9 characters)</li>
                        <li>Repair until has to be greater than £100</li>
                    </ul>
                </details>
                <details>
                    <summary>My Product Type is not listed</summary>
                    <ul>
                        <li>Please select 'Other' and list it in</li>
                    </ul>
                </details>
                <details>
                    <summary>My Manufacturer is not listed</summary>
                    <ul>
                        <li>Please select 'Other' and list it in</li>
                    </ul>
                </details>
                <button className="btn btn-info">Feedback</button>
            </div>
            <Spacer mb={4} />
                
            <JobForm />
        </main>
        
    );
}

export default RequestForm;
