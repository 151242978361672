import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from 'yup';
import Popup from "./Popup";

const API_URL = process.env.REACT_APP_API_URL

const JobForm = () => {
    
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");    
    const [isOpen, setIsOpen] = useState(false);
    const [messagePopup, setMessagePopup] = useState("");

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    return(
        <>
        <Formik 
            initialValues={{
                policyNo: "",
                repairReference: "",
                policyExpiry: "",
                purchasePrice: "", 
                repairUntil: "",
                firstName: "",
                lastName: "", 
                address1: "", 
                address2: "",
                town: "",
                county: "", 
                postCode: "", 
                telephone: "", 
                mobile: "",
                email: "",
                productType: "", 
                manufacturer: "", 
                model: "",
                fault: "",
                riskType: "", 
                additional: ""
            }}
            validationSchema={Yup.object({
                policyNo: Yup.string().required('Required'),
                repairReference: Yup.string().required('Required'),
                policyExpiry: Yup.date().required('Required'),
                purchasePrice: Yup.number(),
                repairUntil: Yup.number().moreThan(100,'Has to be more than £100').required('Required'),
                firstName: Yup.string().required('Customer First Name is Required'),
                lastName: Yup.string().required('Customer Surname Required'),
                address1: Yup.string().required('Required'),
                address2: Yup.string(),
                town: Yup.string().required('Required'),
                county: Yup.string(),
                postCode: Yup.string().max(9, 'Please enter a valid postcode').required('A Post Code is Required'),
                telephone: Yup.string(),
                mobile: Yup.string().required('Mobile Number Required'),
                email: Yup.string().email('Invalid email address').required('Customer email Required'),
                productType: Yup.string().required('Required').oneOf(
                ["camera", "headphones", "tv", "other"],
                "Select a Product Type"
                ),
                manufacturer: Yup.string().required('Required').oneOf(
                    ["apple","bose","bando","bandw","beats","canon", 
                    "fujifilm","gopro","insta360","jabra","kandao",
                    "nikon","olympus","panasonic","sennheiser","sony","other"]
                ),
                model: Yup.string().required('Required'),
                fault: Yup.string().required('Required'),
                riskType: Yup.string().required('Required').oneOf(["accidental", "breakdown"], "Please Select a Risk Type"),
                additional: Yup.string()
            })}
            onSubmit={async (values) => {
                fetch(`${API_URL}/jobs`, {
                    method: 'POST',
                    body: JSON.stringify({
                        "policy-no": values.policyNo.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"),
                        "repair-reference": values.repairReference.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"),
                        "policy-expiry": values.policyExpiry.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"),
                        "purchase-price": JSON.stringify(values.purchasePrice), 
                        "repair-until": JSON.stringify(values.repairUntil),
                        "first-name": values.firstName.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"),
                        "last-name": values.lastName.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"), 
                        address1: values.address1.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"),
                        address2: values.address2.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"),
                        town: values.town.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"),
                        county: values.county.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"),
                        "post-code": values.postCode.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"),
                        "telephone": values.telephone.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"),
                        "mobile": values.mobile.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"),
                        "mail-address": values.email.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"),
                        "product-type": values.productType.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"),
                        manufacturer: values.manufacturer.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"),
                        model: values.model.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"),
                        fault: values.fault.replace(/(\r\n\t|\n|\r|\t)/gm,"").replace(/(")/gm,"'"), 
                        "risk-type": values.riskType.replace(/(\r\n\t|\n|\r)/gm,"").replace(/(")/gm,"'"),
                        additional: values.additional
                    }),
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                        "Accept": "application/json; charset=UTF-8",
                        "Connection": "close",
                        "session-id": JSON.parse(localStorage.getItem('currentUser'))["session-id"]
                    }
                }).then(result => {
                    if (result.status === 201){
                        return result.json().then(json => {
                            setMessagePopup("Job Number: " + values.repairReference + " has been submitted")
                            window.scrollTo({top: 0, behavior: 'smooth'});
                            setIsOpen(!isOpen);
                            values.policyNo = "";
                            values.repairReference = "";
                            values.policyExpiry = "";
                            values.purchasePrice = 0;
                            values.repairUntil = 0;
                            values.firstName = "";
                            values.lastName = "";
                            values.address1 = "";
                            values.address2 ="";
                            values.town = "";
                            values.county = "";
                            values.postCode = "";
                            values.telephone = ""; 
                            values.mobile = "";
                            values.email = "";
                            values.productType = "";
                            values.manufacturer = "";
                            values.model = "";
                            values.fault = "";
                            values.riskType = "";
                            values.additional = "";
                        })
                    } else {
                        setIsError(true);
                        setErrorMsg(result.json()["error-message"])
                    }
                })
            }}
        >
            <Form>
                <fieldset className="form-row">
                    <legend>Policy Details</legend>
                    <div className="col-md-3 mb-3">
                        <label htmlFor="policyNo">Policy No. (*)</label>
                        <Field name="policyNo" id="policyNo" className="form-control border border-danger" placeholder="AMZxxxxx-xxxxx" type="text" aria-required required enterkeyhint="next"/>
                        <ErrorMessage component="div" className="text-danger" name="policyNo" />
                    </div>
                    <div className="col-md-3 mb-3">
                        <label htmlFor="repairReference">Repair Reference (*)</label>
                        <Field name="repairReference" id="repairReference" className="form-control border border-danger" placeholder="AMZUKxxxxxxx" type="text" required enterkeyhint="next"/>
                        <ErrorMessage component="div" className="text-danger" name="repairReference" />
                    </div>
                    <div className="col-md-2 mb-3">
                        <label htmlFor="policyExpiry">Policy Expiry (*)</label>
                        <Field name="policyExpiry" id="policyExpiry" className="form-control border border-danger" type="date" required enterkeyhint="next"/>
                        <ErrorMessage component="div" className="text-danger" name="policyExpiry" />
                    </div>
                
                </fieldset>
                
                <fieldset className="form-row">
                    <legend>Price</legend>
                    <div className="col-md-2 mb-3">
                        <label htmlFor="purchasePrice">Purchase Price</label>
                        <div className="input-group-prepend">
                            <div className="input-group-text">£</div>
                            <Field name="purchasePrice" id="purchasePrice" className="form-control border border-primary" type="number" inputmode="numeric" step="any" placeholder="" enterkeyhint="next"/>
                        </div>
                        <ErrorMessage  inputmode="numeric"component="div" className="text-danger" name="purchasePrice" />
                    </div>
                    <div className="col-md-2 mb-3">
                        <label htmlFor="repairUntil">Repair Up To (*)</label>
                        <div className="input-group-prepend">
                            <div className="input-group-text">£</div>                            
                            <Field name="repairUntil" id="repairUntil" className="form-control border border-danger" type="number" inputmode="numeric" step="any" placeholder="" required enterkeyhint="next"/>
                        </div>
                        <ErrorMessage component="div" className="text-danger" name="repairUntil" />
                    </div>
                </fieldset>

                <fieldset className="form-row mt-5"> 
                    <legend>Customer Details</legend>
                    <div className="col-12 col-md-4">
                        <label htmlFor="firstName">Customer First Name (*)</label>
                        <Field name="firstName" id="firstName" className="form-control border border-danger" type="text" placeholder="" required enterkeyhint="next"/>
                        <ErrorMessage component="div" className="text-danger" name="firstName" />
                    </div>
                    <div className="col-12 col-md-4">
                        <label htmlFor="lastName">Customer Last Name (*)</label>
                        <Field name="lastName" id="lastName" className="form-control border border-danger" type="text" placeholder="" required enterkeyhint="next"/>
                        <ErrorMessage component="div" className="text-danger" name="lastName" />
                    </div>
                    
                    <div className="col-12 col-md-8">
                        <label htmlFor="address1">Address 1 (*)</label>
                        <Field name="address1" id="address1" className="form-control border border-danger" type="text" placeholder="" required enterkeyhint="next"/>
                        <ErrorMessage component="div" className="text-danger" name="address1" />
                    </div>                
                    <div className="col-12 col-md-8">
                        <label htmlFor="address2">Address 2 (Optional)</label>
                        <Field name="address2" id="address2" className="form-control form-control border border-primary" type="text" enterkeyhint="next"/>
                        <ErrorMessage component="div" className="text-danger" name="address2" />
                    </div>
                    <div className="col-12 col-md-8">
                        <label htmlFor="town">Town (*)</label>
                        <Field name="town" id="town" className="form-control border border-danger" type="text" placeholder="" required enterkeyhint="next"/>
                        <ErrorMessage component="div" className="text-danger" name="town" />
                    </div>
                    <div className="col-12 col-md-6 ">
                        <label htmlFor="county">County (*)</label>
                        <Field name="county" id="county" className="form-control form-control border border-primary" type="text" enterkeyhint="next"/>
                        <ErrorMessage component="div" className="text-danger" name="county" />
                    </div>
                    <div className="col-12 col-md-2">
                        <label htmlFor="postCode">ZIP / Postal Code (*)</label>
                        <Field name="postCode" id="postCode" className="form-control border border-danger" type="text" placeholder="" required enterkeyhint="next"/>
                        <ErrorMessage component="div" className="text-danger" name="postCode" />
                    </div>
                </fieldset>
                
                <fieldset className="form-row mt-5">
                    <legend>Contact Info</legend>
                    
                    <div className="col-md-4">
                        <label htmlFor="mobile">Mobile Telephone (*)</label>
                        <Field name="mobile" id="mobile" className="form-control border border-danger" type="tel" required enterkeyhint="next"/>
                        <ErrorMessage component="div" className="text-danger" name="mobile" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="telephone">Home Telephone (Optional)</label>
                        <Field name="telephone" id="telephone" className="form-control form-control border border-primary" type="tel" enterkeyhint="next"/>
                        <ErrorMessage component="div" className="text-danger" name="telephone" />
                    </div>
                    <div className="col-md-8">
                        <label htmlFor="email">Email Address (*)</label>
                        <Field name="email" id="email" className="form-control border border-danger" type="email" placeholder="" required />
                        <ErrorMessage component="div" className="text-danger" name="email" />
                    </div>                   
                </fieldset>

                <fieldset className="form-row mt-5">
                    <legend>Product Details</legend>
                    <div className="col-12 col-md-2">
                        <label htmlFor="productType">Product Type (*)</label>
                        <Field name="productType" id="productType" as="select" className="custom-select border border-danger" required>
                            <option defaultValue value="">Select</option>
                            <option value="camera">Camera</option>
                            <option value="headphones">Headphones</option>
                            <option value="tv">TV</option>
                            <option value="other">Other</option>
                        </Field>
                        <ErrorMessage component="div" className="text-danger" name="productType" />
                    </div>
                    <div className="col-12 col-md-2">
                        <label htmlFor="manufacturer">Manufacturer (*)</label>
                        <Field name="manufacturer" id="manufacturer" as="select" className="custom-select border border-danger" required>
                            <option defaultValue value="">Select</option>
                            <option value="apple">Apple</option>
                            <option value="bose">Bose</option>
                            <option value="bando">B&O</option>
                            <option value="bandw">B&W</option>
                            <option value="beats">Beats</option>
                            <option value="canon">Canon</option>
                            <option value="fujifilm">Fujifilm</option>
                            <option value="gopro">Go-Pro</option>
                            <option value="insta360">Insta360</option>
                            <option value="jabra">Jabra</option>
                            <option value="kandao">Kandao</option>
                            <option value="nikon">Nikon</option>
                            <option value="olympus">Olympus</option>
                            <option value="panasonic">Panasonic</option>
                            <option value="sennheiser">Sennheiser</option>
                            <option value="sony">Sony</option>
                            <option value="other">Other</option>
                        </Field>
                        <ErrorMessage component="div" className="text-danger" name="manufacturer" />
                    </div>
                    <div className="col-12 col-md-4">
                        <label htmlFor="model">Model (*)</label>
                        <Field name="model" id="model" className="form-control border border-danger" type="text" placeholder="" required enterkeyhint="next"/>
                        <ErrorMessage component="div" className="text-danger" name="model" />
                    </div>
                    <div className="col-12 col-md-8">
                        <label htmlFor="fault">Fault Reported (*)</label>
                        <Field name="fault" id="fault" as="textarea" className="form-control border border-danger" placeholder="" required/>
                        <ErrorMessage component="div" className="text-danger" name="fault" />
                    </div>
                    <div className="col-12 col-md-5">
                        <label htmlFor="riskType">Risk Type (*)</label>
                        <Field name="riskType" id="riskType" as="select" className="custom-select border border-danger" required>
                            <option defaultValue value="">Select</option>
                            <option value="accidental">AD (Accident Breakdown)</option>
                            <option value="breakdown">BD (Breakdown Fault)</option>
                        </Field>
                        <ErrorMessage component="div" className="text-danger" name="riskType" />
                    </div>
                    <div className="col-12 col-md-8">
                        <label htmlFor="additional">Additional Information (Optional)</label>                    
                        <Field name="additional" id="additional" as="textarea" className="form-control border border-primary" placeholder="" enterkeyhint="done"/>
                        <ErrorMessage component="div" className="text-danger" name="additional" />
                    </div>    
                </fieldset>
                <button className="btn btn-primary mt-5" type="submit">Submit</button>
            </Form> 
        </Formik>
        {isOpen && <Popup content={
            <div>
                <b>Report Submitted</b>
                <p>{messagePopup}</p>
            </div>} 
            handleClose={togglePopup}
        />}
        
        </>
    
    );
};

export default JobForm;