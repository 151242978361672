import styled from "styled-components";
import { space } from 'styled-system';


const Card = styled.div`
    box-sizing: border-box;
    max-width: 410px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;`;

    
const Form = styled.div`
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
    text-align: center;
    `;

const Input = styled.input`
    border: 1px solid #999;
    margin-bottom: 1rem;
    font-size: 0.8rem;
    `;


const Logo = styled.img`
    width: 50%;
    margin-bottom: 1rem;
    `;

const Error = styled.div`
    background-color: red;
    `;

const Footer = styled.footer`
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
`

const Spacer = styled.div(space)

export { Form, Input, Logo, Card, Error, Footer, Spacer};